import React from "react"
import styled from "styled-components"

import marketing from "../../../assets/images/icons/marketing-icon.svg"

function Hero() {
  return (
    <Container>
      <Section gap={"3rem"}>
        <Wrapper isColumn={true} noGap={true}>
          <TitleSpan>Writers Way</TitleSpan>
          <Title>Marketing Services</Title>
        </Wrapper>
        <Wrapper isGrid={true}>
          <Description>
            At Writers Way Solutions, we understand that in today's digital
            landscape, effective marketing is essential for businesses to
            thrive. Our comprehensive suite of marketing services is designed to
            elevate your brand, engage your audience, and drive measurable
            results.
          </Description>
          <ImageContainer>
            <ImageSvg src={marketing} />
          </ImageContainer>
        </Wrapper>
      </Section>

      <Section gap={".5rem"}>
        <SecondaryHeader>Our Services</SecondaryHeader>
        <Description>
          Our Marketing Services give you everything you need to reach your
          marketig goals, combining the most powerful tools and services to help
          you market your book. We pair our top-notch editing,
          designing and marketing features with our Editor's Choice program, a
          unique initiative to help books achieve editorial superiority.
        </Description>
      </Section>
      <Divider></Divider>
    </Container>
  )
}

export default Hero

const Container = styled.main`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 4rem calc((100vw - 1200px) / 2);
`

const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => (gap ? gap : "1rem")};
`

const Wrapper = styled.div`
  display: ${({ isGrid }) => (isGrid ? "grid" : "flex")};
  flex-direction: ${({ isColumn }) => (isColumn ? "column" : "row")};
  gap: ${({ noGap }) => (noGap ? "0" : "1rem")};
  grid-template-columns: 1fr 1fr;
`

const TitleSpan = styled.h1`
  color: ${props => props.theme.colors.dark4};
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1;
`

const Title = styled.h1`
  color: ${props => props.theme.colors.accent7};
  font-size: 5.5rem;
`

const Description = styled.p`
  color: ${props => props.theme.colors.dark4};
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: justify;
`
const SecondaryHeader = styled.h4`
  color: ${props => props.theme.colors.accent5};
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.5;
`

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
`
const ImageSvg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  overflow: hidden;
`

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${props => props.theme.colors.dark3};
  margin-top: 3rem;
`
